import { AxiosError, AxiosResponse } from 'axios';
import IFile from './IFile';

export enum SubscriptionTypes {
  SEAL = 'seal',
  PREPAID = 'prepaid',
}

export default interface IOrganisation {
  domain_name: string;
  id: number;
  name: string;
  email?: string;
  no_of_accounts: number;
  no_of_active_profiles: number;
  no_of_editing_profiles: number;
  no_of_profiles: number;
  no_of_unprinted_profiles: number;
  inserted_at: string;
  last_login: string | null;
}

export interface IOrganisationData {
  data: IOrganisation[];
}

export interface IOrganisationUser {
  id: number;
  organisation_id: number;
  role: 'org_admin' | 'org_editor';
  user: {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
  };
  groups: IOrganisationGroup[];
}

export interface IOrganisationGroup {
  id: number;
  group_hash: string;
  name: string;
  description?: string | null;
  organisation_id: number;
  organisation_name: string;
  group_logo_file_id?: number | null;
  group_logo?: IFile;
  bg_color: string;
  button_bg_color: string;
  button_text_color: string;
  text_color: string;
  editors: IOrganisationUser[];
  profiles_count: number;
}

export interface IOrganisationEditor {
  id: number;
  organisation_id: number;
  role_id: number;
  user: IOrganisationUser;
}

export interface IOrganisationSettingsContactExchange {
  company_name: boolean;
  email: boolean;
  job_title: boolean;
  mobile_number: boolean;
  name: boolean;
  company_consent: boolean;
}

export interface IOrganisationSettings {
  bg_color?: string;
  button_bg_color?: string;
  button_text_color?: string;
  colour_settings?: string;
  is_contact_exchange_sms_enabled: boolean;
  is_cardholder_editing_enabled: boolean;
  company_logo?: IFile;
  company_consent_file?: IFile;
  company_consent_message?: string;
  company_logo_default?: boolean;
  company_logo_file_id?: number | null;
  company_consent_file_id?: number | null;
  contact_exchange: IOrganisationSettingsContactExchange;
  font?: string;
  id?: number;
  text_color?: string;
  group_id?: number;
  payment_terms?: boolean;
}

export interface IOrganisationUserList {
  data: IOrganisationUser[];
}

export interface IOrganisationDeleteErrorResponse extends AxiosResponse {
  data: {
    errors: string[];
  };
}

export interface IOrganisationDeleteError extends AxiosError {
  response: IOrganisationDeleteErrorResponse;
}

export interface IPilot {
  id: number;
  name: string;
  saas_enabled: boolean;
  inserted_at: string;
  subscription: IPlatformSubscription | null;
  total_profiles: number;
  platform_subscription_status: string;
}

export interface IBillingAddress {
  city: string;
  country: string;
  customer_id: string;
  first_name: string;
  last_name: string;
  line1: string;
  line2: string;
  organisation_id: number;
  state: string;
  zip: string;
}

export interface IOrganisationInvite {
  id: number;
  email: string;
  role: string;
  group_ids: number[];
}

export enum IOrganisationUpdateEvent {
  view_contact = 'view:contact',
  received_contact = 'receive:contact',
  sent_contact = 'send:contact',
  edit_profile = 'edit:profile',
  details_exchange = 'details:exchange',
}

export interface IOrganisationProfile {
  count: number;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  photo: IOrganisationProfilePhoto;
}

export interface IOrganisationProfilePhoto {
  id: number;
  file_size: number;
  name: string;
  original_url: string;
}

export interface IOrganisationUpdatesProfile {
  first_name: string;
  id: number;
  last_name: string | null;
}

export interface IOrganisationUpdates {
  event: IOrganisationUpdateEvent;
  inserted_at: string;
  profile: IOrganisationUpdatesProfile;
  sender?: IOrganisationUpdatesProfile;
  receiver?: IOrganisationUpdatesProfile;
}

export interface IOrganisationUpdate {
  top_profile: IOrganisationProfile | null;
  updates: IOrganisationUpdates[];
}

export interface IPlatformSubscription {
  id: number;
  email: string;
  type: string;
  order_placed: string;
  first_name: string;
  last_name: string;
  edit_url: string;
  items: IPlatformSubscriptionItem[];
  billing_attempts: IPlatformSubscriptionBillingAttempt[];
  billing: IPlatformSubscriptionBilling;
  shipping: IPlatformSubscriptionShipping;
  payment_method: IPlatformSubscriptionPaymentMethod;
  cancelled_at: string | null;
}

export interface IPlatformSubscriptionItem {
  id: number;
  quantity: number;
  original_amount: number;
  final_amount: number;
  variant_id: string;
}

export interface IPlatformSubscriptionBillingAttempt {
  id: number;
  date: string;
  status: string;
}

export interface IPlatformSubscriptionBilling {
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  province: string;
  province_code: string;
  country: string;
  country_code: string;
}

export interface IPlatformSubscriptionShipping {
  first_name: string;
  last_name: string;
  company: string;
  address: string;
  city: string;
  zip: string;
  province: string;
  province_code: string;
  country: string;
  country_code: string;
}

export interface IPlatformSubscriptionPaymentMethod {
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}
