import { AxiosResponse } from 'axios';
import { BASE_URL } from '../config';
import axiosApiService from '@/services/axiosApiService';
import IAnalytics, {
  AnalyticsResponse,
  SuperuserAnalyticsResponse,
} from '@/types/IAnalytics';
import IList, { IListPaging } from '@/types/IList';

const buildAnalyticsQuery = ({
  from,
  to,
  groupIds,
  profileIds,
  orgIds,
}: {
  from?: string;
  to?: string;
  groupIds?: number[];
  profileIds?: number[];
  orgIds?: number[];
}) => {
  const params = new URLSearchParams();
  if (from) params.append('from', from);
  if (to) params.append('to', to);
  if (groupIds) params.append('group_ids', groupIds.join(','));
  if (profileIds) params.append('profile_ids', profileIds.join(','));
  if (orgIds) params.append('organisation_id', orgIds.join(','));

  return params;
};

const analyticsAPI = {
  oldListAnalytics: ({
    orgID = -1,
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    search = '',
    groupIDs = [],
  }: Partial<IList>): Promise<
    AxiosResponse<{ data: IAnalytics[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${orgID}/analytics?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }${`&group_ids=${groupIDs.join(',')}`}`,
    );
  },
  listAnalytics: (
    orgID: number,
    from?: string,
    to?: string,
    groupIds?: number[],
    profileIds?: number[],
  ) => {
    const params = buildAnalyticsQuery({
      from,
      to,
      groupIds,
      profileIds,
    });

    return axiosApiService.get<AnalyticsResponse>(
      `${BASE_URL}/api/organisations/${orgID}/analytics2?${params.toString()}`,
    );
  },
  listAdminAnalytics: (
    from?: string,
    to?: string,
    groupIds?: number[],
    profileIds?: number[],
  ) => {
    const params = buildAnalyticsQuery({ from, to, groupIds, profileIds });

    return axiosApiService.get<SuperuserAnalyticsResponse>(
      `${BASE_URL}/admin/api/analytics2?${params.toString()}`,
    );
  },
  recordLogin: (orgID: number) => {
    return axiosApiService.post(
      `${BASE_URL}/api/organisations/${orgID}/record_login`,
    );
  },
};

export default analyticsAPI;
