import axios, { AxiosResponse } from 'axios';

import axiosApiService from '@/services/axiosApiService';
import IDesign from '@/types/IDesign';
import IFile from '@/types/IFile';
import { IListPaging } from '@/types/IList';
import IOrder, { IOrderUser } from '@/types/IOrderV2';

import { BASE_URL } from '../config';

export interface IListOrders {
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: string;
  status?: string;
  search?: string;
}

const ordersAPI = {
  listOrders: ({
    page = 1,
    pageSize = 20,
    sort = 'date',
    order = 'desc',
    status = 'new',
    search = '',
  }: Partial<IListOrders>): Promise<
    AxiosResponse<{ data: IOrder[]; paging: IListPaging }>
  > => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/orders?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }${status && status !== 'all_orders' ? `&status=${status}` : ``}`,
    );
  },

  listOrderUsers: (orderId: number): Promise<AxiosResponse<IOrderUser[]>> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/orders/${orderId}/users`,
    );
  },

  listOrdersUserOrganisation: (
    organisation_id: number,
    {
      page = 1,
      pageSize = 20,
      sort = 'date',
      order = 'desc',
      search = '',
    }: Partial<IListOrders>,
  ): Promise<AxiosResponse<{ data: IOrder[]; paging: IListPaging }>> => {
    return axiosApiService.get(
      `${BASE_URL}/api/organisations/${organisation_id}/orders?page_size=${pageSize}&page=${page}&sort[${sort}]=${order}${
        search !== '' ? `&search=${search}` : ''
      }`,
    );
  },

  uploadFile: (
    id: number,
    fileID: number,
    checkbox: boolean,
  ): Promise<AxiosResponse<{ data: IFile }>> => {
    return axiosApiService.post(
      `${BASE_URL}/admin/api/v2/orders/${id}/upload`,
      {
        file_id: fileID,
        send_email_flag: checkbox,
      },
    );
  },

  countOrders: (): Promise<AxiosResponse> => {
    return axiosApiService.get(`${BASE_URL}/admin/api/v2/orders_count`);
  },

  showChangeRequest: (id: number): Promise<AxiosResponse> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/orders/${id}/change_requests?limit=latest`,
    );
  },

  showOrderChangeRequest: (id: number): Promise<AxiosResponse> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/orders/${id}/change_requests`,
    );
  },

  orderChangeRequest: (
    uuid: string,
    note: string,
    change_request_file_ids: number[],
  ): Promise<AxiosResponse> => {
    return axiosApiService.post(`${BASE_URL}/api/v2/orders/change_requests`, {
      uuid,
      change_request_file_ids: change_request_file_ids,
      note: note,
    });
  },

  AdminOrderChangeRequest: (
    order_id: number,
    note?: string,
    change_request_file_ids?: number[],
  ): Promise<AxiosResponse> => {
    return axiosApiService.post(
      `${BASE_URL}/admin/api/v2/orders/${order_id}/change_requests`,
      {
        change_request_file_ids: change_request_file_ids,
        note: note,
      },
    );
  },

  showOrder: (order_uuid: string): Promise<AxiosResponse> => {
    return axiosApiService.get(
      `${BASE_URL}/api/v2/orders/show?order_uuid=${order_uuid}`,
    );
  },
  countOrderv2: () => {
    return axiosApiService.get(`${BASE_URL}/admin/api/v2/orders_count`);
  },
  acceptDesign: (id: number): Promise<AxiosResponse> => {
    return axiosApiService.post(`${BASE_URL}/api/v2/orders/accept_design`, {
      id: id,
    });
  },

  deleteOrders: (orders_number: string[]): Promise<AxiosResponse> => {
    return axiosApiService.delete(`${BASE_URL}/api/v2/orders/delete`, {
      data: {
        orders_number,
      },
    });
  },

  assignToDifferentOrg: ({ order_id = 0, organisation_id = 0 }) => {
    return axiosApiService.put(`${BASE_URL}/admin/api/v2/orders/${order_id}`, {
      organisation_id,
    });
  },
  updateOrder: ({
    order_id = 0,
    stage = '',
  }: {
    order_id: number;
    stage: string;
  }): Promise<AxiosResponse<void>> => {
    return axiosApiService.patch(
      `${BASE_URL}/admin/api/v2/orders/${order_id}`,
      {
        order: {
          stage,
        },
      },
    );
  },
  updateOrderPriority: (
    order_id: number,
    is_priority: boolean,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.patch(
      `${BASE_URL}/admin/api/v2/orders/${order_id}`,
      {
        order: {
          is_priority,
        },
      },
    );
  },
  organisation_past_order: (
    organisation_id: number,
  ): Promise<AxiosResponse<{ data: IOrder[]; paging: IListPaging }>> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/orders?organisation_id=${organisation_id}`,
    );
  },
  showOrderDesignFile: (
    order_id: number,
  ): Promise<AxiosResponse<IDesign[]>> => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/orders/${order_id}/show_order_design_file`,
    );
  },
  uploadOrderDesign: (
    order_id: number,
    organisation_id: number,
    design_file_ids: number[],
  ): Promise<AxiosResponse<IDesign>> => {
    return axiosApiService.post(
      `${BASE_URL}/admin/api/v2/orders/${order_id}/upload_design`,
      {
        organisation_id,
        design_file_ids,
      },
    );
  },
  updateOrderDetail: (
    order_id: number,
    stage?: string,
    alternateEmail?: string,
    priority?: boolean,
    alternate_email_flag?: boolean,
    order_tag?: string,
  ): Promise<AxiosResponse<void>> => {
    return axiosApiService.patch(
      `${BASE_URL}/admin/api/v2/orders/${order_id}`,
      {
        order: {
          stage,
          alternate_email: alternateEmail,
          is_priority: priority,
          alternate_email_flag,
          tag: order_tag,
        },
      },
    );
  },
  showShippingZones: (
    country_code: string,
    province_code: string,
  ): Promise<AxiosResponse> => {
    return axiosApiService.post(`${BASE_URL}/api/shipping_zone`, {
      country_code,
      province_code,
    });
  },
  downloadQrCodes: (order_id: number) => {
    return axiosApiService.get(
      `${BASE_URL}/admin/api/v2/orders/${order_id}/qr_codes`,
      {
        responseType: 'blob',
      },
    );
  },
};

export default ordersAPI;
