import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowRightIcon } from '@heroicons/react/24/outline';

import { AxiosError } from 'axios';
import { useWindowSize } from 'usehooks-ts';

import cardsAPI from '@/api/cards';
import profilesAPI from '@/api/profiles';
import MESSAGES from '@/constants/messages-en';
import useAppState, { TemplatesTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';
import { IProfileBase } from '@/types/IProfile';

import ActivationKeySearch from './ActivationKeySearch';
import { Alert } from './Alert';
import { BUTTON_KIND } from './Button';
import Modal from './Modals/Modal';
import Search from './Search';

/**
 * code ported over from old profiles page
 */
type LocationParams = {
  location: {
    state: {
      success: string;
      setupInfo: boolean;
      activationKey?: string;
      activationKeyFromLogin?: string;
      checkout?: string;
    };
  };
};

type ActivateProductsModalProps = LocationParams & {
  setActionResult: React.Dispatch<React.SetStateAction<Alert | undefined>>;
  refreshCards: () => void;
};

export function ActivateProductsButton({
  location,
  setActionResult,
  refreshCards,
}: ActivateProductsModalProps) {
  const { orgID } = useAuth();
  const history = useHistory();
  const { selectTemplatesTab } = useAppState();
  const { width: windowWidth } = useWindowSize();

  const activationKeyFromSignUp = location?.state?.activationKey;
  const activationKeyFromLogin = location?.state?.activationKeyFromLogin;
  const setupInfo = location?.state?.setupInfo;

  const [isOpen, setIsOpen] = useState(false);
  const [activationKey, setActivationKey] = useState(
    activationKeyFromSignUp || '',
  );
  const [profileSearch, setProfileSearch] = useState('');
  const [activationKeyType, setActivationKeyType] = useState('');
  const [profileDataForSearch, setProfileDataForSearch] = useState<
    IProfileBase[]
  >([]);
  const [showOptions, setShowOptions] = useState(false);
  const [profileIDForActivation, setProfileIDForActivation] = useState<
    number | undefined
  >();
  const [isSetupInfoOpen, setIsSetupInfoOpen] = useState(false);
  const [isEditAfterActivateOpen, setIsEditAfterActivateOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (orgID) {
      if (activationKeyFromSignUp) {
        activateCards();
      } else if (activationKeyFromLogin) {
        setActivationKey(activationKeyFromLogin);
        setIsOpen(true);
      } else {
        showSetupInfo();
      }
    }
  }, [orgID, activationKeyFromLogin, activationKeyFromSignUp]);

  function showSetupInfo(override: boolean = false) {
    const setupInfoShown = localStorage.getItem('setupInfo');
    if ((setupInfo || override) && !setupInfoShown) {
      setIsSetupInfoOpen(true);
      localStorage.setItem('setupInfo', 'true');
    }
  }

  async function listProfilesForSearch(newSearch: string) {
    const res = await profilesAPI.listProfiles({ orgID, search: newSearch });

    setProfileDataForSearch(
      res.data.data.filter(
        profile => profile.status === 'active' || profile.status === 'editing',
      ),
    );

    return res.data;
  }

  async function showActivationKeyType(
    newActivationKey: string = activationKey,
  ) {
    const res = await cardsAPI.showActivatationKeyType(newActivationKey);

    if (res.data.data.type && activationKeyType !== res.data.data.type) {
      setActivationKeyType(res.data.data.type);
    }
  }

  function activateCards() {
    if (orgID === undefined) {
      return;
    }

    setIsLoading(true);
    setActionResult(undefined);

    const assignToExistingProfile = profileIDForActivation;

    cardsAPI
      .activateCards(activationKey, orgID, profileIDForActivation)
      .then(res => {
        if (activationKeyFromSignUp && res.data?.data.profile?.id) {
          setProfileIDForActivation(res.data.data.profile.id);
          setIsEditAfterActivateOpen(true);
        }

        refreshCards();
        if (assignToExistingProfile === undefined) {
          setActionResult({
            outcome: 'success',
            message:
              'Your product has successfully been activated & profile created.',
            action: (
              <a
                className="flex items-center gap-1 font-medium text-emerald-400 hover:text-emerald-600"
                href={`/edit-profile/${res.data.data.profile?.id}`}
              >
                Edit profile
                <ArrowRightIcon className="w-4 h-4" />
              </a>
            ),
          });
        } else {
          setActionResult({
            outcome: 'success',
            message:
              'Your product has successfully been activated & assigned to selected profile.',
            action: (
              <a
                className="flex items-center gap-1 font-medium text-emerald-400 hover:text-emerald-600"
                href={`/edit-profile/${res.data.data.profile?.id}`}
              >
                Edit profile
                <ArrowRightIcon className="w-4 h-4" />
              </a>
            ),
          });
        }
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          setActionResult({
            outcome: 'error',
            message: MESSAGES.profile.activate.notFound,
          });
        } else if (e.response?.status === 422) {
          setActionResult({
            outcome: 'error',
            message: MESSAGES.profile.activate.usedKey,
          });
        } else {
          setActionResult({
            outcome: 'error',
            message: MESSAGES.error.generic,
          });
        }
      })
      .then(() => setIsLoading(false))
      .finally(() => setIsOpen(false));
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonTitle={windowWidth < 640 ? 'Activate' : 'Activate products'}
        buttonKind={BUTTON_KIND.PRIMARY}
        dialogTitle={MESSAGES.profile.activate.heading}
        dialogDescription={
          <div className="mb-4 text-gray-500 text-sm font-normal leading-[150%] sm:w-full">
            Enter the activation key from your Shopify order email or on the
            screen when you tap your physical card on your phone.
            <br />
            <br />
            <span className="font-medium">Please note - </span>You will only
            need to activate your product if you've ordered a Tapt Lite, Tapt
            Mobile, Tapt Mini, Tapt Stand &amp; Tapt Wallet. Tapt Custom &amp;
            Tapt Black cards will arrive active and ready to use. <br />
            <br />
            If you're still having trouble, please
            <a href="https://tapt.io/pages/sales-enquiry">&nbsp;get in touch</a>
          </div>
        }
        successButtonText="Activate"
        successButtonKind={BUTTON_KIND.PURPLE_SECONDARY_MD}
        onSuccess={activateCards}
        isLoading={isLoading}
        isDisabled={!activationKey}
      >
        <a
          href="https://tapt.io/pages/how-to-use"
          rel="noreferrer"
          target="_blank"
        >
          Learn more
        </a>
        <div className="mt-6">
          <ActivationKeySearch
            id={`ActivationKey-${activationKey}`}
            label="Card activation key"
            search={activationKey}
            setSearch={setActivationKey}
            fetchQuery={showActivationKeyType}
          />
          <div className="relative mt-6 mb-6">
            <Search
              id={`ProfileList-1-20-date-desc-${profileSearch}`}
              label="Assign product to existing profile (optional)"
              description="If you do not assign this product to an existing profile, we'll create a new one instead."
              placeholder={
                activationKeyType === 'card_generated'
                  ? 'Search for Profile ID or Name'
                  : 'Only available for card generated keys'
              }
              search={profileSearch}
              disabled={activationKeyType !== 'card_generated'}
              setSearch={value => {
                if (value !== profileSearch) {
                  setProfileIDForActivation(undefined);
                  setShowOptions(true);
                }
                setProfileSearch(value);
              }}
              fetchQuery={newSearch => listProfilesForSearch(newSearch)}
            />
            {profileSearch && showOptions && (
              <div className="absolute right-0 z-10 w-full mt-2 origin-top-left bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {profileDataForSearch && profileDataForSearch?.length ? (
                    profileDataForSearch?.map((item: IProfileBase) => (
                      <button
                        type="button"
                        key={item.id}
                        className="w-full px-3 py-2 text-left appearance-none cursor-pointer hover:bg-gray-200"
                        onClick={() => {
                          setProfileIDForActivation(item.id);
                          setShowOptions(false);
                          setProfileSearch(`${item.id}`);
                        }}
                      >
                        #{item.id}{' '}
                        <span className="text-gray-500">
                          {item.first_name} {item.last_name}
                        </span>
                      </button>
                    ))
                  ) : (
                    <li className="px-3 py-2 text-center">
                      No matching items found
                    </li>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isSetupInfoOpen}
        setIsOpen={setIsSetupInfoOpen}
        dialogHeroVideo="/images/customise-profile.mp4"
        dialogTitle="Start customising your digital profile"
        dialogDescription={
          <>
            Now your cards are all set up, start customising your digital
            profile to match your branding.{' '}
            <a
              href="https://help.tapt.io/en/articles/8449589-editing-your-tapt-profile-keeping-your-information-updated"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </>
        }
        onSuccess={() => {
          selectTemplatesTab(TemplatesTab.PROFILE_DESIGN);
          history.push(`/templates`);
        }}
        successButtonText="Go to profile settings"
        cancelButtonText="Close"
        isLoading={isLoading}
        large
      />
      <Modal
        isOpen={isEditAfterActivateOpen}
        setIsOpen={setIsEditAfterActivateOpen}
        dialogTitle="You've activated a new card!"
        dialogDescription="If you would like to edit this card's profile now, click the 'Edit profile' button below. Otherwise, you can click 'Edit profile' next to the new profile in the cards list to edit later."
        onSuccess={() =>
          history.push(`/edit-profile/${String(profileIDForActivation)}`)
        }
        successButtonText="Edit profile"
        cancelButtonText="I'll edit later"
      />
    </>
  );
}
