import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ArrowRightIcon,
  CheckIcon,
  ChevronLeftIcon,
  ArrowsPointingOutIcon as ExpandIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon,
} from '@heroicons/react/24/outline';

import Lottie, { AnimationItem } from 'lottie-web';

import CustomiseGroupProfileLottie from '@/assets/lotties/customise-group-profiles.json';
import EditOrganisationProfileLottie from '@/assets/lotties/edit-organisation-profile.json';
import EditProfileDetailsLottie from '@/assets/lotties/edit-profile-details.json';
import EnableContactExchangeLottie from '@/assets/lotties/enable-contact-exchange.json';
import EnableUserProfileEditLottie from '@/assets/lotties/enable-user-profile-editing.json';
import HowToShareProfileLottie from '@/assets/lotties/how-to-share-profile.json';
import ImproveYourProfileLottie from '@/assets/lotties/improve-your-profile.json';
import useAppState, { SettingsTab, TemplatesTab } from '@/hooks/useAppState';
import useAuth from '@/hooks/useAuth';

import ExpandPopup from './ExpandPopup';
import LottieAnimation from './LottieAnimation';

function CTAwithCards() {
  const { userScope } = useAuth();
  const { selectSettingsTab, selectTemplatesTab } = useAppState();
  const isSuperUser = userScope === 'admin';
  const hasPremiumPlan = !isSuperUser && true;

  const lotties = useRef<AnimationItem[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [isExpanded]);

  const lottieAnimations = [
    {
      name: 'Edit profile details',
      animationData: EditProfileDetailsLottie,
      linkLabel: 'Visit',
      url: '/',
      isExternal: false,
    },
    hasPremiumPlan
      ? {
          name: 'Customise group profiles',
          animationData: CustomiseGroupProfileLottie,
          linkLabel: 'Visit',
          url: '/groups',
          isExternal: false,
        }
      : {
          name: 'Design organisation profile',
          animationData: EditOrganisationProfileLottie,
          linkLabel: 'Visit',
          url: '/templates',
          isExternal: false,
        },
    {
      name: 'Enable contact exchange',
      animationData: EnableContactExchangeLottie,
      linkLabel: 'Visit',
      url: '/templates',
      isExternal: false,
      onClick: () => {
        selectTemplatesTab(TemplatesTab.CONTACT_EXCHANGE);
      },
    },
    {
      name: 'Enable user profile editing ',
      animationData: EnableUserProfileEditLottie,
      linkLabel: 'Visit',
      url: '/settings',
      isExternal: false,
      onClick: () => {
        selectSettingsTab(SettingsTab.PROFILE_EDITING);
      },
    },
    {
      name: 'How to share your profile',
      animationData: HowToShareProfileLottie,
      linkLabel: 'Learn more',
      url: 'https://tapt.io/pages/how-to-use/',
      isExternal: true,
    },
    {
      name: 'Improve your profile!',
      animationData: ImproveYourProfileLottie,
      linkLabel: 'Show me',
      url: 'https://help.tapt.io/en/articles/8449588-optimising-your-tapt-profile-for-maximum-impact',
      isExternal: true,
    },
  ];

  useEffect(() => {
    lotties.current = lottieAnimations.map((ld, index) => {
      const anim = Lottie.loadAnimation({
        container: document.getElementById(`${index}`)!,
        animationData: ld.animationData,
        renderer: 'svg',
        autoplay: false,
        loop: true,
      });
      return anim;
    });
  }, [lottieAnimations]);

  useEffect(() => {
    lotties.current.forEach((lottie, index) => {
      if (index === currentStep) {
        lottie.play();
      } else {
        lottie.stop();
      }
    });
  }, [currentStep]);

  const CurrentStepData = lottieAnimations[currentStep];

  return (
    <>
      <div className="w-full h-full">
        <div className="flex flex-col justify-between h-full">
          <div className="">
            <div className="flex justify-end">
              <button
                className="cursor-pointer"
                type="button"
                onClick={() => setIsExpanded(true)}
              >
                <ExpandIcon />
              </button>
            </div>
            <div className="rounded-lg [&>div]:!py-0">
              {lottieAnimations.map((l, index) =>
                currentStep === index ? (
                  <LottieAnimation
                    key={index}
                    animationData={l.animationData}
                  />
                ) : null,
              )}
            </div>
          </div>
          <div>
            <div className="pt-3">
              <div className="flex items-center justify-between">
                <div className="w-full h-3 max-w-full bg-gray-200 rounded-xl xl:max-w-[24rem]">
                  <div
                    className="h-3 transition-all rounded-full bg-brand-500"
                    style={{
                      width: `${(currentStep + 1) * 16.67}%`,
                    }}
                  />
                </div>
                <div className="pl-4 text-sm font-normal text-gray-400 xl:text-base min-w-[6.875rem]">
                  Step {currentStep + 1} of 6
                </div>
              </div>
              <h4 className="pt-4 pb-2 text-xl font-medium text-gray-900">
                {CurrentStepData.name}
              </h4>
              {CurrentStepData.isExternal ? (
                <a
                  href={CurrentStepData.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={CurrentStepData.onClick}
                  className="flex items-center cursor-pointer hover:text-brand-500"
                >
                  {CurrentStepData.linkLabel}
                  <span className="pl-1">
                    <ExternalLinkIcon />
                  </span>
                </a>
              ) : (
                <Link
                  to={CurrentStepData.url}
                  className="flex items-center cursor-pointer hover:text-brand-500"
                  onClick={CurrentStepData.onClick}
                >
                  {CurrentStepData.linkLabel}
                  <span className="pl-1">
                    <ExternalLinkIcon />
                  </span>
                </Link>
              )}
              <div className="flex items-center justify-between mt-[1.125rem]">
                {currentStep ? (
                  <button
                    className="flex items-center justify-center w-12 h-12 bg-transparent border border-gray-300 border-solid rounded-full cursor-pointer [&>svg>path]:stroke-[#3F3F46]"
                    type="button"
                    onClick={() => {
                      if (currentStep > 0) {
                        setCurrentStep(prevStep => prevStep - 1);
                      }
                    }}
                  >
                    <ChevronLeftIcon />
                  </button>
                ) : (
                  ''
                )}
                <div className="ml-auto">
                  {currentStep >= lottieAnimations.length - 1 ? (
                    <button
                      className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-brand-500"
                      type="button"
                    >
                      <CheckIcon width={17} height={13} />
                    </button>
                  ) : (
                    <button
                      className="flex items-center justify-center w-12 h-12 rounded-full cursor-pointer bg-brand-500"
                      type="button"
                      onClick={() => {
                        if (currentStep < lottieAnimations.length - 1) {
                          setCurrentStep(prevStep => prevStep + 1);
                        }
                      }}
                    >
                      <ArrowRightIcon
                        className="text-gray-50 w-6 h-6"
                        strokeWidth={2}
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExpanded && (
        <ExpandPopup
          currentStep={currentStep}
          onClose={() => {
            setIsExpanded(false);
          }}
          activeAnimation={lottieAnimations}
          onNext={() => {
            if (currentStep < lottieAnimations.length - 1) {
              setCurrentStep(prevStep => prevStep + 1);
            }
          }}
          onPrev={() => {
            if (currentStep > 0) {
              setCurrentStep(prevStep => prevStep - 1);
            }
          }}
        />
      )}
    </>
  );
}

export default CTAwithCards;
